import React from "react";
import classes from "./Select.module.scss";

type Props = {
  options: any[];
  defaultValue: string;
  isDefaultValue: boolean;
  pickOption?: string;
  [props: string]: any;
}

const Select = ({options = [], defaultValue = '', isDefaultValue = false, pickOption, ...props}: Props) => {
    return (
        <div className={classes.select__wrapper}>
          <select className={classes.select} required {...props}>
            <option selected key='all' value='all'>Все сайты</option>
            {isDefaultValue && <option value="" hidden>{defaultValue}</option>}
            {options.map(({ key, value }, index) =>
              key === pickOption
                ? <option selected key={index} value={key}>{`${value}`}</option>
                : <option key={index} value={key}>{`${value}`}</option>
            )}
          </select>
        </div>
    );
};

export default Select;
