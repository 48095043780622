import React, { useContext, useEffect, useState } from 'react';
import classes from './Home.module.scss';
import Container from '../../components/container/Container';
import TitleBig from '../../components/titleBig/TitleBig';
import RequestAddress from '../../components/requestAddress/RequestAddress';
import { Requests } from '../../components/requests/Requests';
import Title from '../../components/title/Title';
import TitleSmall from '../../components/titleSmall/TitleSmall';
import TextH4 from '../../components/ui/typographuck/textH4/TextH4';
import Loader from '../../components/ui/loader/Loader';
import { HOST, socket } from '../../constants';
import { Context } from '../../context/context';
import ButtonDownload from '../../components/ui/buttons/buttonDownload/ButtonDownload';
import { Pagination } from '../../components/pagination/Pagination';
import ButtonClose from '../../components/ui/buttons/buttonClose/ButtonClose';
import TextP from '../../components/ui/typographuck/textP/TextP';
import Select from '../../components/ui/select/Select';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import 'react-datepicker/dist/react-datepicker.css';
import { ru } from 'date-fns/locale/ru';
import SelectHost from '../../components/ui/select/SelectHost';

export const Home = () => {
  const { isAuth } = useContext(Context);

  const [hostname, setHostname] = useState('');
  const [region, setRegion] = useState(1);
  const [regions, setRegions] = useState([]);
  const defaultOptions: Array<{ key: string; value: string; }> = [];
  const [options, setOptions] = useState(defaultOptions);
  const [hosts, setHosts] = useState(defaultOptions);
  const [filterRegion, setFilterRegion]: [filterRegion: number, setFilterRegion: any] = useState(0);
  const [filterHost, setFilterHost]: [filterHost: string, setFilterHost: any] = useState('all');
  const [startDate, setStartDate]: [startDate: Date | null, setStartDate: any] = useState(null);
  const [endDate, setEndDate]: [endDate: Date | null, setEndDate: any] = useState(null);
  const [searchArray, setSearchArray] = useState([]);
  const [result, setResult]: [result: any[], setResult: any] = useState([]);
  const [currentPage, setCurrentPage]: [currentPage: number, setCurrentPage: any] = useState(1);
  const [pageSize, setPageSize]: [pageSize: number, setPageSize: any] = useState(10);
  const [totalItems, setTotalItems]: [totalItems: number, setTotalItems: any] = useState(0);

  const getFilters = () => {
    const data = { limit: parseInt(String(pageSize)), page: parseInt(String(currentPage)) };
    if (parseInt(String(filterRegion)) !== 0) {
      data['region'] = parseInt(String(filterRegion));
    }
    if (filterHost !== 'all') {
      data['host'] = filterHost;
    }
    if (startDate) {
      // @ts-ignore
      data['dateStart'] = moment(startDate).utcOffset(0, true).format();
    }
    if (endDate) {
      // @ts-ignore
      data['dateEnd'] = moment(endDate).add(1, 'days').utcOffset(0, true).format();
    }
    return data;
  };
  const resetFilters = () => {
    setFilterRegion(0);
    setStartDate(null);
    setEndDate(null);
  };

  const getStringFilters = () => {
    const filters = getFilters();
    // @ts-ignore
    const filtersToString = Object.keys(filters).reduce((accum, currentValue) => {
      accum[currentValue] = filters[currentValue].toString();
      return accum;
    }, {});
    return new URLSearchParams(filtersToString).toString();
  };
  const getResponseResult = () => {
    const params = getStringFilters();

    fetch(`${HOST}/history/filter?${params}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${isAuth}`,
      },
    }).then(async (response) => {
      const data = await response.json();
      const { meta, docs } = data;
      setResult(docs);
      setTotalItems(meta.total);
    });
  };
  useEffect(() => {
    socket.on('connect', () => {
      socket.emit('subscribeHistories', { token: isAuth, count: pageSize }, (data) => {
      });
    });
  }, []);
  useEffect(() => {
    fetch(`${HOST}/history/hosts`, {
      method: 'get',
      headers: {
        'Authorization': `Bearer ${isAuth}`,
      },
    }).then(async (res) => {
      const hostsData = await res.json();

      const optionsData: { key: any, value: any }[] = [];
      for (const item of hostsData) {
        optionsData.push({ key: item, value: item });
      }
      setHosts(optionsData.sort((a, b) => {
        if (a.key > b.key) return 1;
        if (a.key < b.key) return -1;
        return 0;
      }));
    });
  }, []);

  useEffect(() => {
    socket.on('update', (data) => setResult((prevState) => prevState.map(obj => obj.id === data.id ? data : obj)));
  }, []);

  useEffect(() => {
    const handleCreate = (data) => {
      if (currentPage === 1) {

        if (parseInt(String(filterRegion)) && parseInt(String(filterRegion)) !== data.region) {
          return;
        }
        if (filterHost && filterHost !== data.hostname) {
          return;
        }
        if (startDate && moment(data.createdAt).isBefore(startDate)) {
          return;
        }
        if (endDate && moment(data.createdAt).isAfter(moment(endDate).add(1, 'days'))) {
          return;
        }
        if (!hosts.map(_=>_.key).includes(data.hostname)) {
          setHosts([...hosts, {key: data.hostname, value: data.hostname}].sort((a, b) => {
            if (a.key > b.key) return 1;
            if (a.key < b.key) return -1;
            return 0;
          }));
        }
        setResult((prevState) => [data, ...prevState].slice(0, pageSize));
      }
    };

    socket.on('create', handleCreate);

    return () => {
      socket.off('create', handleCreate);
    };
  }, [filterRegion, filterHost, startDate, endDate]);


  const downloadHistory = async () => {
    const { limit, ...filtersWithoutLimit } = getFilters();
    filtersWithoutLimit.page = 1;
    const response = await fetch(`${HOST}/history/excel`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${isAuth}`,
      },
      body: JSON.stringify(filtersWithoutLimit),
    });
    if (response.ok) {
      const blob = await response.blob();

      const url = window.URL.createObjectURL(
        new Blob([blob]),
      );

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `${(new Date()).toLocaleDateString('ru-RU')}-report.xlsx`,
      );

      document.body.appendChild(link);
      link.click();
      link?.parentNode?.removeChild(link);
    }
  };

  const sendCheckOne = () => {
    searchArray.filter(val => !!val).forEach((search) => {
      const data = {
        hostname,
        search,
        region: Number(region),
      };

      fetch(`${HOST}/snippet/check-one`, {
        method: 'POST',
        headers: {
          'Accept': 'text/html',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${isAuth}`,
        },
        body: JSON.stringify(data),
      }).catch(console.error);
    });
  };

  useEffect(() => {
    getResponseResult();
  }, [currentPage, pageSize, filterRegion, filterHost, startDate, endDate]);

  return (
    <div className={classes.home}>
      <Container>
        <div className={classes.home__title}>
          <TitleBig children="Сервис проверки на сниппет фильтр" />
        </div>
        <RequestAddress hostnameState={[hostname, setHostname]} regionState={[region, setRegion]}
                        regionsState={[regions, setRegions]} onSetOptions={setOptions} />
        <Requests searchState={[searchArray, setSearchArray]} sendCheckOne={sendCheckOne} />
        <div className={classes.filters_wrap}>
          <div className="d-flex space-between align-items-center">
            <Title children="Фильтры" />
          </div>
          <div className="d-flex space-between align-items-end">
            <div className={classes.filters}>
              <div className={classes.filters__cell}>
                <TitleSmall children="Регион" />
                {<Select
                  isDefaultValue={false}
                  defaultValue="Выберите регион"
                  options={options}
                  pickOption={filterRegion.toString()}
                  onChange={({ target: { value } }) => setFilterRegion(value)}
                />}
              </div>
              <div className={classes.filters__cell}>
                <TitleSmall children="Сайт" />
                {<SelectHost
                  isDefaultValue={false}
                  defaultValue="Выберите сайт"
                  options={hosts}
                  pickOption={filterHost.toString()}
                  onChange={({ target: { value } }) => setFilterHost(value)}
                />}
              </div>
              <div className={classes.filters__cell}>
                <TitleSmall children="Начало периода" />
                <DatePicker
                  selected={startDate}
                  locale={ru}
                  dateFormat="dd.MM.YYYY"
                  isClearable
                  className={classes.filters__input}
                  onChange={(date) => setStartDate(date)} />
              </div>
              <div className={classes.filters__cell}>
                <TitleSmall children="Конец периода" />
                <DatePicker
                  selected={endDate}
                  locale={ru}
                  dateFormat="dd.MM.YYYY"
                  className={classes.filters__input}
                  isClearable
                  onChange={(date) => setEndDate(date)} />
              </div>
            </div>
            <div className="d-flex flex-end align-items-center">
              <div
                className={filterRegion || startDate || endDate ? classes.button : classes.button + ' ' + classes.disabled}
                onClick={resetFilters}>
                <ButtonClose buttonColor="#000000" />
                <TextP children="Сбросить фильтры" />
              </div>
            </div>
          </div>
        </div>
        <div className={classes.resultOfChecking}>
          <div className="d-flex space-between align-items-center">
            <Title children="Результат проверки" />
            <ButtonDownload onClick={downloadHistory} children="скачать" />
          </div>
          <div className={classes.resultOfChecking__content}>
            <div className={classes.table}>
              <div className={classes.table__titles}>
                {['Дата', 'Сайт', 'Запрос', 'Регион', 'Позиция', 'Статус'].map((name) =>
                  <TitleSmall key={name} style={{ margin: 0, textAlign: 'center' }} children={name} />,
                )}
              </div>
              <div>
                {result.length ? result.map((data, index) => (
                  <div key={data.id}
                       className={index % 2 === 0 ? classes.table__row : `${classes.table__row} ${classes.table__row_dark}`}>
                    <div className={classes.table__col}>{(new Date(data.createdAt)).toLocaleDateString('ru-RU', {
                      hour: '2-digit',
                      minute: '2-digit',
                    })}</div>
                    <div className={classes.table__col}>{data.hostname}</div>
                    <div className={classes.table__col}>{data.search}</div>
                    <div className={classes.table__col}>{regions[data.region]}</div>
                    <div className={classes.table__col}>{data.position}</div>
                    <Loader process={data.process} found={data.found} passed={data.passed} />
                  </div>
                )) : <TextH4 children="Результатов пока нет" />}
              </div>
              {result.length ?
                <Pagination
                  source={'results'}
                  currentPage={parseInt(currentPage.toString())}
                  totalCount={totalItems}
                  pageSize={pageSize}
                  onPageChange={page => setCurrentPage(page)}
                  onPageSizeChange={size => setPageSize(size)}></Pagination> : ''}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};
